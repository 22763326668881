import Image from 'next/image';
import Link from 'next/link';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';

type ClinicHeaderProps = {
  logoUrl: string;
  clinicName: string;
  isLicensed: boolean;
  completeAddress: string;
  allClinicServicesLink: string;
};

export const ClinicHeader: React.FC<ClinicHeaderProps> = ({ logoUrl, clinicName, isLicensed, completeAddress, allClinicServicesLink }) => (
  <div className="flex gap-3">
    {logoUrl && (
      <Image
        src={logoUrl}
        alt="logo"
        className="h-[70px] w-[70px] rounded-full border border-gray-200 object-contain object-center"
        width={70}
        height={70}
      />
    )}
    <div className="flex flex-col gap-y-1">
      <span className="flex items-center gap-2 text-lg font-semibold text-neutral-darker">
        <span data-cy="card-name">{clinicName}</span>
        {isLicensed && <CheckBadgeIcon className="h-7 w-7 " />}
      </span>
      <span className="text-xs font-normal text-neutral-lighter">{completeAddress}</span>
      <Link href={allClinicServicesLink} className="text-xs text-primary underline" prefetch={false}>
        Vedi tutte le prestazioni
      </Link>
    </div>
  </div>
);

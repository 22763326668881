import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ELTooltip } from '@davincihealthcare/elty-design-system-react';
import { formatReservationPrice } from '@/app/(checkout)/_utils/checkout';

const PriceSection = ({
  hasChangedSelectedSlot,
  hasDynamicPrice,
  isLoadingAvailabilities,
  isLicensedClinic,
  price,
}: {
  hasChangedSelectedSlot: boolean;
  hasDynamicPrice: boolean;
  isLoadingAvailabilities: boolean;
  price?: number;
  isLicensedClinic: boolean;
}) => {
  const canShowPriceInfo = hasDynamicPrice && !hasChangedSelectedSlot;
  const title = hasDynamicPrice ? 'a partire da' : 'prezzo';
  const isPriceAvailable = typeof price === 'number'; // Price 0 is ok

  return (
    <div className="flex items-center">
      {isPriceAvailable && isLicensedClinic ? (
        <div className="flex flex-col gap-1.5 text-nowrap">
          <span className="w-full text-center text-sm font-normal text-neutral-darker">{title}</span>
          <div className="flex h-full items-center gap-1  text-sm font-normal text-neutral-darker">
            <p className="text-medium text-semibold text-2xl text-neutral-darker md:text-3xl">{formatReservationPrice(price)} &#8364;</p>

            {canShowPriceInfo && (
              <span className="flex h-full">
                <ELTooltip text={'Il prezzo potrebbe variare in base al giorno o orario scelto'}>
                  <InformationCircleIcon className="h-5 w-5 text-primary-active" />
                </ELTooltip>
              </span>
            )}
          </div>
        </div>
      ) : (
        'Prezzo non disponibile'
      )}
    </div>
  );
};

export default PriceSection;

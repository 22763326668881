import { ELLink } from '@davincihealthcare/elty-design-system-react';

type Props = {
  path: string;
  title: string;
  description: string;
};

export const NoAvailabilitiesInfo: React.FC<Props> = ({ path, title, description }) => (
  <div className="flex h-full flex-col items-center justify-center gap-5 text-center">
    <p className="text-base font-medium text-neutral-lighter">{title}</p>
    <ELLink aria-label={description} size="small" variant="outlined" color="primary" label={description} href={path} />
  </div>
);

import { NoAvailabilitiesInfo } from './NoAvailabilitiesInfo';
import clsx from 'clsx/lite';
import { useAvailabilitySlots } from '@/app/_components/widgets/listing/_hooks/useAvailabilitySlots';
import { getStartOfDayTime } from '@/app/_components/widgets/listing/_utils/time';

const SpecialityAvailability: React.FC<{ title: string; description?: string; onClick: () => void; isSelected: boolean }> = ({
  title,
  description,
  onClick,
  isSelected,
}) => {
  // !TODO: use button from design system after it supports children
  return (
    <button
      onClick={onClick}
      data-cy="card-availability"
      className={clsx(
        'flex h-10 cursor-pointer flex-col items-center  justify-center rounded-lg border border-primary text-primary hover:border-primary-active hover:text-primary-active',
        isSelected && 'border-primary-active text-primary-active',
      )}
    >
      <span className="semi-bold text-sm">{title}</span>
      {description && <span className="text-sm font-normal">{description}</span>}
    </button>
  );
};

const DAYS_TO_SHOW = 5;

const getDateTitle = (date: number) => {
  const todayMs = getStartOfDayTime(Date.now());
  const tomorrowMs = getStartOfDayTime(Date.now(), 1);

  // if today
  if (date === todayMs) {
    return 'Oggi';
  } else if (date === tomorrowMs) {
    return 'Domani';
  } else {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString('it', { day: '2-digit', month: '2-digit' });
  }
};

export const CardAvailabilities: React.FC<{
  specialityId: string;
  doctorId: string;
  workgroupId: string;
  workgroupPath: string;
  selectedDay: number | 'OTHER' | null;
  onDayClick: (date: number) => void;
  onOtherAvailabilitiesClick: () => void;
}> = ({ specialityId, doctorId, workgroupId, workgroupPath, selectedDay, onDayClick, onOtherAvailabilitiesClick }) => {
  const { groupedDays, sortedDates, isInitialLoading } = useAvailabilitySlots({
    specialityId,
    doctorId,
    workgroupId,
  });

  if (isInitialLoading)
    return (
      <div className="grid grid-cols-3 gap-3">
        {Array.from({ length: 6 }).map((_, index) => (
          <div key={index} className="h-10 w-full animate-pulse rounded-lg bg-slate-300"></div>
        ))}
      </div>
    );

  if (sortedDates.length === 0)
    return (
      <NoAvailabilitiesInfo path={workgroupPath} title="Al momento non ci sono orari disponibili" description="Vedi tutte le prestazioni" />
    );

  const hasOtherAvailabilities = Object.keys(groupedDays).length > DAYS_TO_SHOW;

  return (
    <div className="grid grid-cols-3 gap-3">
      {sortedDates.slice(0, DAYS_TO_SHOW).map(date => {
        const title = getDateTitle(date);

        return (
          <SpecialityAvailability
            key={date}
            title={title}
            isSelected={selectedDay === date}
            description={`${groupedDays[date].length} orari`}
            onClick={() => {
              onDayClick(date);
            }}
          />
        );
      })}
      {/* Fill empty slots to ensure 2 rows */}
      {!hasOtherAvailabilities &&
        Array.from({ length: Math.max(0, 6 - sortedDates.length) }).map((_, index) => (
          <div key={`empty-${index}`} className="h-10 rounded-lg " />
        ))}

      {/* Other availabilities always in position 6 */}
      {hasOtherAvailabilities && (
        <SpecialityAvailability title="Altri orari" isSelected={selectedDay === 'OTHER'} onClick={onOtherAvailabilitiesClick} />
      )}
    </div>
  );
};

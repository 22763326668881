import { useCallback, useMemo, useState } from 'react';
import { makeFullAddress } from '@/utils/common';
import { makeClinicSlug } from '@/utils/shared';
import { useIsTantosvago } from '@/app/_hooks/useIsTantosvago';
import { CardAvailabilities } from './CardAvailabilities';
import { DavinciLogo } from './DavinciLogo';
import { ClinicHeader } from './ClinicHeader';
import { BookingActions } from './BookingActions';
import { NoAvailabilitiesInfo } from './NoAvailabilitiesInfo';
import PriceSection from './PriceSection';
import { AlgoliaClinicV2, License, PaymentType, SpecialityChannel } from '@/types/algolia-types';
import { ELBadge } from '@davincihealthcare/elty-design-system-react';
import { HomeIcon, VideoCameraIcon } from '@heroicons/react/24/outline';
import { AvailabilityModal } from '@/app/_components/widgets/listing/cardV2/AvailabilityModal';
import { getCheckoutURL } from '@/utils/checkout';
import { SlotAvailability } from '@/app/_components/widgets/listing/types';
import { ROUTES } from '@/routes';
import { useRouter } from 'next/navigation';

type Props = {
  item: AlgoliaClinicV2;
};

const CardV2: React.FC<Props> = ({ item }) => {
  const isLicensed = item?.license && (item.license as License) !== License.None;
  const router = useRouter();

  const { address, cap, city, logoUrl, name: clinicName } = item;

  const isOnline = useMemo(
    () => item.specializations.specialityChannels?.includes(SpecialityChannel.Online),
    [item.specializations.specialityChannels],
  );

  const isDomicile = item.domiciliaryCare;

  const hasOnsitePayment = useMemo(() => item.availablePayments?.includes(PaymentType.Onsite), [item.availablePayments]);

  const { label: specialityName, id: specialityId, hasDynamicPrice, price } = item.specializations;
  const doctorId = item.specializations.doctors?.[0]?.id;
  const workgroupId = item.ambulatoryId;

  const isTantoSvago = useIsTantosvago();
  const completeAddress = useMemo(() => makeFullAddress(address, cap, city) ?? '', [address, cap, city]);
  const [selectedDay, setSelectedDay] = useState<number | 'OTHER' | null>(null);
  const allClinicServicesLink = useMemo(() => makeClinicSlug(item, specialityName, specialityId), [item, specialityId, specialityName]);

  const handleSubmit = useCallback(
    (selectedSlot: SlotAvailability) => {
      if (!selectedSlot) return;

      const checkoutUrl = getCheckoutURL({
        pathname: ROUTES.CHECKOUT_RECAP.pathname,
        ambulatoryId: selectedSlot.workgroupId,
        startTime: selectedSlot.start,
        availabilitySlotId: selectedSlot.id,
        specialityId: selectedSlot.specialityId,
      });

      router.push(checkoutUrl);
    },
    [router],
  );

  return (
    <div className="overflow-x-hidden rounded-lg shadow-cardListing">
      {hasOnsitePayment && !isTantoSvago && (
        <div className="flex items-center justify-center gap-2 bg-brandGreen-200 px-6 py-2 text-primary lg:justify-end">
          <DavinciLogo />
          {<span>Prenota ora e paga in struttura</span>}
        </div>
      )}
      <div className="grid grid-cols-1 gap-4 bg-neutral-surface pb-4 lg:grid-cols-2 lg:gap-5 lg:pb-6">
        <div className="flex flex-col gap-4 px-4 pt-4 lg:min-w-[300px] lg:pt-6">
          <div className="flex flex-col">
            <ClinicHeader
              logoUrl={logoUrl}
              clinicName={clinicName}
              isLicensed={isLicensed}
              completeAddress={completeAddress}
              allClinicServicesLink={allClinicServicesLink}
            />
          </div>
          <div className="-ml-4 h-0.5 w-[calc(100%+2rem)] bg-slate-100 md:hidden" />

          <div className="flex items-center justify-between gap-3">
            <div className="flex flex-col gap-1">
              <span className="text-base font-semibold text-neutral-darker">{specialityName}</span>
              {isDomicile && <ELBadge label="A domicilio" color="primary" size="medium" Icon={HomeIcon} />}
              {isOnline && <ELBadge label="Prestazioni online" color="primary" size="medium" Icon={VideoCameraIcon} />}
            </div>
            {isLicensed && (
              <PriceSection
                hasChangedSelectedSlot={false}
                hasDynamicPrice={hasDynamicPrice}
                isLoadingAvailabilities={false}
                isLicensedClinic={isLicensed}
                price={price}
              />
            )}
          </div>
        </div>
        <div className="flex w-full flex-col gap-4 px-4 lg:px-6 lg:pt-6">
          {!isLicensed || !specialityId || !doctorId ? (
            <NoAvailabilitiesInfo
              path={allClinicServicesLink}
              title="Per prenotare contatta la struttura"
              description="Visualizza il profilo"
            />
          ) : (
            <>
              {selectedDay && (
                <AvailabilityModal
                  specialityId={specialityId}
                  doctorId={doctorId}
                  workgroupId={workgroupId}
                  initialSelectedDay={selectedDay}
                  onClose={() => setSelectedDay(null)}
                  onSubmit={handleSubmit}
                />
              )}
              <CardAvailabilities
                specialityId={specialityId}
                doctorId={doctorId}
                workgroupId={workgroupId}
                selectedDay={selectedDay}
                onDayClick={day => setSelectedDay(day)}
                workgroupPath={allClinicServicesLink}
                onOtherAvailabilitiesClick={() => setSelectedDay('OTHER')}
              />
              <BookingActions onClick={() => setSelectedDay('OTHER')} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardV2;

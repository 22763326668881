'use client';
import { ClinicSearchResult, FilterProps, OrderType, getAlgoliaSearchResponse } from '@/api/algolia';
import { MayBeSearchLocation, getDeliveryModeFilterFromQueryParameters, makeSearchParams } from '@/utils/search';
import { getEnumValueFromString, getStringFromQueryParam } from '@/utils/string';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import Paginator from '@/app/_components/paginator';
import Container from '@/app/_components/container';
import { Bars4Icon, MapIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { trackLicensedItemInSearchList } from '@/utils/trackingGtm';
import ListingFilters from './listingFilters';
import { ListingInfo } from './types';
import { AdjustmentsVerticalIcon } from '@heroicons/react/24/solid';
import { convertSearchResponseIntoListingInfo } from '@/utils/listing';
import { getMacroSpeciality } from '@/utils/macroSpecialities';
import { Sex } from '@/types-aggregatore';
import dynamic from 'next/dynamic';
import { useTantoSvagoStore } from '@/store';
import { makeMarkersList } from '@/utils/maps';
import Modal from '@/app/_components/modal';
import { ELButton, ELLoader } from '@davincihealthcare/elty-design-system-react';
import clsx from 'clsx/lite';
import Image from 'next/image';
import { DAVINCI_CDN } from '@/costants/defaultValues';
import { getUserLocation } from '@/api/mapbox';
import CardV2 from '@/app/_components/widgets/listing/cardV2';

type ListingProps = {
  service?: string;
  locationInfo?: MayBeSearchLocation;
  searchFilters?: FilterProps;
  searchResultsServerSide?: ClinicSearchResult;
  paginationPadding?: 'lg' | 'md';
  filterLight?: boolean;
  showSearchInfo?: boolean;
  setLoadingState?: (isLoading: boolean) => void;
  loadingState?: boolean;
  showSearchNearYouCTA?: boolean;
};

const DynamicMap = dynamic(() => import('@/app/_components/widgets/map/interactive'), { ssr: false });

const Listing = ({
  service,
  locationInfo,
  searchFilters,
  searchResultsServerSide,
  paginationPadding = 'lg',
  filterLight,
  showSearchInfo = true,
  setLoadingState,
  loadingState,
  showSearchNearYouCTA = false,
}: ListingProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSSR, setSSR] = useState(!!searchResultsServerSide);
  const [isShowingMap, setShowMap] = useState(false);
  const [listingInfo, setListingInfo] = useState<ListingInfo>(
    searchResultsServerSide ? convertSearchResponseIntoListingInfo(service, locationInfo?.name, searchResultsServerSide) : {},
  );
  const params = useSearchParams();
  const [location, setLocation] = useState<MayBeSearchLocation | undefined>(locationInfo);
  const [isShowingSearchNearYou, setIsShowingSearchNearYou] = useState(showSearchNearYouCTA);

  const [userGeolocation, setUserGeolocation] = useState<null | { coordinates: [number, number]; place: string }>();
  // const [userGeolocationActive, setUserGeolocationActive] = useState(false);
  const isSpecializationSearch = !!getMacroSpeciality(getStringFromQueryParam(params?.get('service') ?? ''));

  const tantoSvagoStore = useTantoSvagoStore();
  const userGeolocationActive = params?.get('userGeolocationActive');
  const isUserGeolocationActive = getStringFromQueryParam(userGeolocationActive ?? undefined) === 'true' ? true : false;

  const doNewSearch = useCallback(async () => {
    const parsedPage = Number(params?.get('page') ?? '1');
    const currentPage = isNaN(parsedPage) ? 1 : parsedPage;

    const queryServiceFilter = getStringFromQueryParam(params?.get('filterService') ?? '');
    const queryDoctorSexFilter = getStringFromQueryParam(params?.get('filterDoctorSex') ?? '');
    const queryDeliveryFilter = getDeliveryModeFilterFromQueryParameters(params?.get('exclude') ?? '');
    const queryOrderFilter = getStringFromQueryParam(params?.get('filterOrder') ?? '');

    const { searchHealthcareService, searchLocation, searchPage, searchFilter } = await makeSearchParams(
      {
        service: params?.get('service') ?? '',
        lat: params?.get('lat') ?? '',
        lng: params?.get('lng') ?? '',
        page: currentPage,
        location: params?.get('location') ?? '',
      },
      service,
      location,
      {
        ...searchFilters,
        service: queryServiceFilter,
        doctorSex: getEnumValueFromString(Sex, queryDoctorSexFilter),
        excludeDomicilary: queryDeliveryFilter?.excludeDomicilary,
        excludeOnline: queryDeliveryFilter?.excludeOnline,
        excludeOnsite: queryDeliveryFilter?.excludeOnsite,
        order: getEnumValueFromString(OrderType, queryOrderFilter),
        preferFavorite: isSpecializationSearch,
        onlinePayment: !!tantoSvagoStore.apiHeaders.Authorization,
      },
    );
    const searchResponse = await getAlgoliaSearchResponse({
      text: searchHealthcareService,
      searchLocation,
      page: searchPage,
      filterObj: searchFilter,
    });
    const newListingInfo = convertSearchResponseIntoListingInfo(searchHealthcareService, searchLocation?.name, searchResponse);
    setListingInfo(newListingInfo);
    trackLicensedItemInSearchList(newListingInfo);
    setLoadingState?.(false);

    if (isUserGeolocationActive && (newListingInfo.total ?? 0) <= 0) {
      setShowMap(false);
    }
  }, [
    params,
    service,
    location,
    searchFilters,
    isSpecializationSearch,
    tantoSvagoStore.apiHeaders.Authorization,
    setLoadingState,
    isUserGeolocationActive,
  ]);

  const listingInfoRef = useRef<HTMLDivElement>(null);

  const scrollToListingStart = () => {
    const offsetTop = 150;
    const targetPosition = listingInfoRef.current?.getBoundingClientRect().top ?? 0;
    const scrollPosition = window.scrollY + targetPosition - offsetTop;

    window.scrollTo({
      top: scrollPosition,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (!showSearchNearYouCTA) window.scrollTo(0, 0);
  }, [listingInfo.results, showSearchNearYouCTA]);

  useEffect(() => {
    if (isSSR) setSSR(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSSR && !isModalOpen) doNewSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doNewSearch, isModalOpen]);

  useEffect(() => {
    if (!userGeolocation || userGeolocationActive) return;

    const newParams = new URLSearchParams(params?.toString());
    newParams.delete('page');
    newParams.set('location', userGeolocation.place);
    newParams.set('lat', userGeolocation.coordinates[1]?.toString());
    newParams.set('lng', userGeolocation.coordinates[0]?.toString());
    newParams.set('userGeolocationActive', 'true');
    window.history.pushState(null, '', `?${newParams.toString()}`);
    setUserGeolocation(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGeolocation, userGeolocationActive]);

  const [isSearchNearYouLoading, setIsSearchNearYouLoading] = useState(false);
  const searchNearYou = async () => {
    setIsSearchNearYouLoading(true);
    try {
      const location = await getUserLocation();
      if (!location) {
        setIsSearchNearYouLoading(false);
        return;
      }
      setLocation({
        latitude: location?.userCoordinates?.[0] ? `${location?.userCoordinates?.[0]}` : '',
        longitude: location?.userCoordinates?.[1] ? `${location?.userCoordinates?.[1]}` : '',
        name: location?.userLocationName,
      });
      scrollToListingStart();
      setIsSearchNearYouLoading(false);
      setIsShowingSearchNearYou(false);
    } catch (error) {
      console.error('Error checking location permission:', error);
      setIsSearchNearYouLoading(false);
      return null;
    }
  };

  return (
    <>
      {isShowingSearchNearYou && (
        <div className="flex items-center justify-center">
          {isSearchNearYouLoading ? (
            <ELLoader />
          ) : (
            <ELButton
              aria-label={'Cerca vicino a te'}
              label={'Cerca vicino a te'}
              variant="filled"
              color="secondary"
              onClick={searchNearYou}
              leadingIcon={<MapPinIcon />}
              size="large"
            />
          )}
        </div>
      )}
      {loadingState && (
        <div className="my-24 flex flex-col items-center gap-4">
          <Image src={`${DAVINCI_CDN}/inclinic/images/waiting.png`} width={600} height={600} alt="waiting" className="max-w-[300px]" />
          <div className="flex flex-col items-center justify-center gap-y-4">
            <h3 className="text-center text-lg font-bold">Ricerca in corso...</h3>
            <ELLoader />
          </div>
        </div>
      )}
      <div className={`${loadingState ? 'hidden' : 'visible relative'}`}>
        <ListingFilters applyFilters={!isModalOpen} filterLight={filterLight} />
        {isModalOpen && (
          <Modal
            className="text-primary-90 absolute m-0 h-[100vh] max-h-full w-full max-w-xl overflow-y-auto rounded-lg border bg-white py-2 md:top-2 md:max-h-[650px] md:w-auto"
            isModalOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          >
            <ListingFilters closeModal={() => setIsModalOpen(false)} applyFilters={!isModalOpen} filterLight={filterLight} />
          </Modal>
        )}
        <Container>
          <div ref={listingInfoRef} className="grid grid-cols-1 lg:grid-cols-[4fr_2fr] lg:gap-x-12">
            <div className="grid grid-rows-[auto_1fr]">
              {/* listing header */}
              {listingInfo?.title && (
                <div className="grid grid-cols-2 items-center py-4 md:grid-cols-1 md:py-6">
                  <div className="flex flex-col gap-y-1">
                    {showSearchInfo && <h1 className="text-base font-normal text-neutral-darker">{listingInfo.title}</h1>}
                    <p className="text-xs font-normal text-neutral-lighter md:text-sm">
                      Abbiamo trovato <span className="text-sm font-semibold text-neutral-lighter">{listingInfo?.total ?? 0}</span>{' '}
                      risultati
                    </p>
                  </div>
                  <button className="h-8 w-8 justify-self-end md:hidden" onClick={() => setIsModalOpen(true)} aria-label="show-filters">
                    <AdjustmentsVerticalIcon />
                  </button>
                </div>
              )}
              {/* listing body */}
              {!!listingInfo?.results?.length && (
                <div className="grid grid-rows-1">
                  <div className="grid grid-cols-1 items-start gap-y-4">
                    {listingInfo.results.map(item => {
                      return <CardV2 key={item.objectID} item={item} />;
                    })}
                  </div>
                  <div className={paginationPadding === 'lg' ? `pt-48` : 'pt-10'}>
                    <Paginator totalPages={listingInfo?.pages} />
                  </div>
                </div>
              )}
            </div>
            {/* map */}
            {(!!makeMarkersList(listingInfo?.results ?? [])?.length || isUserGeolocationActive) && isShowingMap ? (
              <>
                {/* mobile/tablet */}
                <div className="block lg:hidden">
                  <div className="absolute inset-0 -left-2 -top-2 z-[90] h-full">
                    <div className="h-screen w-full">
                      <DynamicMap
                        items={listingInfo.results ?? []}
                        setUserGeolocation={setUserGeolocation}
                        isUserGeolocationActive={isUserGeolocationActive}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* desktop */}
                <div className="hidden lg:relative lg:flex lg:min-h-full lg:w-full">
                  <div className="sticky top-0.5 h-screen w-full">
                    <div className="h-full w-full">
                      <DynamicMap
                        items={listingInfo.results ?? []}
                        setUserGeolocation={setUserGeolocation}
                        isUserGeolocationActive={isUserGeolocationActive}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Container>
        <div className={clsx('xl:hidden', !!makeMarkersList(listingInfo?.results ?? [])?.length ? 'relative' : 'hidden')}>
          <div className="fixed bottom-10 z-[1000] flex w-full justify-center">
            <ELButton
              aria-label={isShowingMap ? 'Mostra lista risultati' : 'Mostra mappa'}
              label={isShowingMap ? 'Lista' : 'Mappa'}
              variant="filled"
              color="secondary"
              onClick={() => {
                scrollToListingStart();
                setShowMap(!isShowingMap);
              }}
              leadingIcon={isShowingMap ? <Bars4Icon /> : <MapIcon />}
              size="medium"
              float
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Listing;

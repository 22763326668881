import { FORMATTED_CALLCENTER_TELEPHONE_NUMBER, PREFIXED_CALLCENTER_TELEPHONE_NUMBER } from '@/costants';
import { ELButton } from '@davincihealthcare/elty-design-system-react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

export const BookingActions: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <div className="flex flex-col justify-center gap-3 lg:grid lg:grid-cols-2 lg:pt-6">
    <ELButton
      trailingIcon={<ArrowRightIcon className="h-5 w-5" />}
      color="primary"
      variant="filled"
      size="small"
      aria-label="Scegli orario"
      label="Scegli orario"
      onClick={onClick}
    />
    <div className="w-full text-ellipsis text-center text-base font-normal text-primary">
      Oppure chiama il{' '}
      <a className="font-semibold" href={`tel:${PREFIXED_CALLCENTER_TELEPHONE_NUMBER}`}>
        {FORMATTED_CALLCENTER_TELEPHONE_NUMBER}
      </a>
    </div>
  </div>
);
